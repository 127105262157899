/* eslint-disable react-hooks/exhaustive-deps */
import Header from "../../components/Header";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { set_loader } from "../../../../redux/actions/loader";
import HiddenQrRreader from "../../components/HiddenQrReader";
import { useSelector } from "react-redux";
import { handleAllColorStyles } from "../../../../helpers/dynamic_styles";
import IDCard from "../../../../assets/svg/document.svg";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const dispatch: any = useDispatch();
  const { session } = useSelector((state: any) => state);
  const assets = session?.profile?.assets?.assets;
  const navigate = useNavigate();

  useEffect(() => {
    handleAllColorStyles(assets);
    const timeout: any = setTimeout(() => {
      dispatch(set_loader({ is_loading: false }));
      console.log("CLOSE LOADER");
    }, 5000);

    return () => {
      clearInterval(timeout);
      console.log("CLEAR INTERVAL");
    };
  }, []);

  return (
    <div className="container-fluid h-100">
      <Header />
      <HiddenQrRreader />

      <section className="content-section d-flex flex-column justify-content-center align-items-center">
        <div
          className="d-flex flex-column justify-content-between align-items-center"
          style={{
            background: "rgba(255, 255, 255, 0.1)",
            width: "615px",
            borderRadius: "30px",
            height: "434px",
            padding: "50px",
          }}
        >
          <div
            className="mb-3 bold text-center"
            style={{ fontSize: "20px", width: "330px" }}
          >
            {assets?.fantasilandia_home_title}
          </div>
          <div>
            <img src={IDCard} alt="ID Card" style={{ width: "390px" }} />
          </div>
        </div>

        <div style={{ fontSize: "20px" }} className="text-center mt-5 bold">
          {" "}
          <button className="btn text-white bold" style={{fontSize: "20px"}} onClick={() => navigate("/validate-document")}>
            Si no tienes cedula de identidad,{" "}
            <span style={{ color: "#FFE700", textDecoration: "none" }}>
              presiona aquí.
            </span>
          </button>
        </div>
      </section>
    </div>
  );
};

export default Home;
