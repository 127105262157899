import { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignIn from "../components/SignIn";
import Home from "../modules/DynamicFantasilandia/pages/Home";
import DashboardRouter from "./DashboardRouter";
import Confirmation from "../modules/DynamicFantasilandia/pages/Confirmation";
import ValidateDocument from "../modules/DynamicFantasilandia/pages/DocumentCode";
import PinCode from "../modules/DynamicFantasilandia/pages/PinCode";

export default function DynamicFantasilandiaRouter() {
  const { session } = useSelector((state: any) => state);
  useEffect(() => {
    import("../modules/DynamicFantasilandia/styles/_global_events.scss");
  }, []);
  return (
    <BrowserRouter>
      <SignIn open={!session.is_connected || session.sign_in_component} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/validate-document" element={<ValidateDocument />} />
        <Route path="/pin-code/:action" element={<PinCode />} />
        <Route path="/open-door/:action" element={<Confirmation />} />
        {/** dashboard routes */}
        <Route path="/dashboard/*" element={<DashboardRouter />} />

      </Routes>
    </BrowserRouter>
  );
}
