/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";
import { io } from "socket.io-client";
import { _pushToastMessage } from "../../../../helpers/messages";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { set_loader } from "../../../../redux/actions/loader";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { ValidateQRCodeDataEventsModule } from "../../../../helpers/validate_qrcode_data";
import { set_session } from "../../../../redux/actions/session";
import { set_order } from "../../../../redux/actions/order";
import { set_pickup } from "../../../../redux/actions/pickup";
import FantasilandiaService from "../../../../services/fantasilandia.service";

interface IHiddenQrRreaderProps {
  onChange?: any;
}

const HiddenQrRreader = ({ onChange }: IHiddenQrRreaderProps) => {
  const { session } = useSelector((state: any) => state);
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const inputRef: any = useRef<any>();
  const debouncedSave = useRef(
    debounce((nextValue) => _formatAndCheckData(nextValue), 700)
  ).current;

  /**
   * Focus input
   * clear interval
   * @returns void
   */
  useEffect(() => {
    const interval: any = setInterval(() => {
      inputRef.current.focus();
    }, 100);

    if (session.sign_in_component) {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [session]);

  /**
   * Socket connection
   */
  useEffect(() => {
    const socket: any = io("http://127.0.0.1:3333");
    socket.on("news", (data: any) => {
      console.log(data);
    });

    socket.on("code", (data: any) => {
      const code: any = data.replace(/[\r\n]/gm, "");
      const payload: any = code.toLowerCase();
      _formatAndCheckData(payload);
    });

    socket.on("connect_error", () => console.log("CONECT ERROR"));
    socket.on("reconnect_error", () => console.log("RECONECT ERROR"));

    return () => {
      console.log("Destroy socket connection");
      socket.destroy();
    };
  }, []);

  const _validateCode = async (code: any, target: string = "rut") => {
    try {
      dispatch(set_loader({ is_loading: true }));

      /**
       * Check if exists a reservation with this rut
       * If not, only redirect to confirmation page to create and open the door
       * If exists, set order data and redirect to open box
       */

      const response = await new FantasilandiaService().checkReservation({
        code: code,
        terminal_id: session?.profile?.id,
      });

      dispatch(
        set_order({
          boxes: [],
          company: {},
          order: {},
          new_order: {
            code: code,
            terminal_id: session?.profile?.id,
            boxes: response?.data?.boxes,
          },
        })
      );

      if (!response?.data?.reservation) {
        const hasBoxesAvailable =
          await new FantasilandiaService().getAvailableBoxes({
            terminal_id: session?.profile?.id,
          });

        dispatch(set_loader({ is_loading: false }));

        if (!hasBoxesAvailable?.data.has_boxes) {
          _pushToastMessage({
            text: "No hay casilleros disponibles",
            type: "error",
            header: "Error",
          });
          return;
        }

        navigate("/open-door/delivery");
        return;
      }

      const reservation = response?.data?.reservation;

      if (reservation) {
        dispatch(
          set_pickup({
            reservation: reservation,
            boxes: response.data?.boxes,
          })
        );
        dispatch(set_loader({ is_loading: false }));

        const delivery_user = reservation?.delivery_user;

        if (delivery_user?.delivery_user_config?.info?.method !== "document") {
          navigate("/pin-code/pickup");
          return;
        }

        navigate("/open-door/pickup");
      }
    } catch (e: any) {
      dispatch(set_loader({ is_loading: false }));
      _pushToastMessage({
        text:
          e?.response?.data?.message ||
          e?.message ||
          "El codigo informado no es valido.",
        type: "error",
        header: "Error",
      });
    }
  };

  /* Proccess data from input */
  const _formatAndCheckData = async (qrcode: any) => {
    inputRef.current.value = qrcode?.toLowerCase();

    dispatch(set_loader({ is_loading: true }));

    const terminal_id = session?.profile?.id;

    const validateCodeResponse: any = await ValidateQRCodeDataEventsModule(
      qrcode,
      terminal_id
    );

    dispatch(set_loader({ is_loading: false }));

    inputRef.current.value = "";
    if (!validateCodeResponse.isValid) {
      _pushToastMessage({
        header: "Error",
        text: validateCodeResponse.message,
        type: "success",
      });
      return;
    }

    switch (validateCodeResponse.method) {
      case "master_key":
        dispatch(set_session({ ...session, ...validateCodeResponse.data }));
        navigate("/dashboard");
        inputRef.current.value = "";
        break;
      case "rut_by_document":
        _validateCode(validateCodeResponse.data.rut);
        inputRef.current.value = "";
        break;
      default:
        _pushToastMessage({
          header: "Error",
          text: "No se pudo validar el codigo QR",
          type: "error",
        });
        break;
    }
  };

  const _handleChangeInput = (e: any) => {
    debouncedSave(e?.target?.value);
  };

  return (
    <div style={{ position: "absolute", top: "-100px" }}>
      <input
        type="text"
        ref={inputRef}
        id="codetext"
        style={{ opacity: 1, color: "#000" }}
        onChange={_handleChangeInput}
      />
    </div>
  );
};

export default HiddenQrRreader;
