import api from "./api";
import store from "../redux/store";

class FantasilandiaService {
  constructor() {
    const { session } = store.getState();
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${session.security_token}`;
  }

  getAvailableBoxes = async (payload: { terminal_id: string }) => {
    try {
      const response = await api.get(
        `/terminal/fantasilandia/get-available-boxes?terminal_id=${payload.terminal_id}`
      );
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  checkReservation = async (payload: { code: string; terminal_id: string }) => {
    try {
      const response = await api.get(
        `/terminal/fantasilandia/check-reservation?code=${payload.code}&terminal_id=${payload.terminal_id}`
      );
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  createReservation = async (payload: {
    code: string;
    terminal_id: string;
    pin: string;
    method: string;
  }) => {
    try {
      const response = await api.post(
        `/terminal/fantasilandia/create-reservation`,
        payload
      );
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  finishReservation = async (payload: any) => {
    try {
      const response = await api.post(
        `/terminal/fantasilandia/finish-order`,
        payload
      );
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  checkPin = async (payload: { pin: string; delivery_user_id: string }) => {
    try {
      const response = await api.post(
        `/terminal/fantasilandia/validate-pin`,
        payload
      );
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
}

export default FantasilandiaService;
