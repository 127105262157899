import { _pushToastMessage } from "../../../../helpers/messages";
import FantasilandiaService from "../../../../services/fantasilandia.service";

export const canCreateReservations = async ({
  terminal_id,
}: {
  terminal_id: string;
}) => {
  const hasBoxesAvailable = await new FantasilandiaService().getAvailableBoxes({
    terminal_id: terminal_id,
  });

  if (!hasBoxesAvailable?.data.has_boxes) {
    _pushToastMessage({
      text: "No hay casilleros disponibles",
      type: "error",
      header: "Error",
    });
    return false;
  }

  return true;
};

export const formatDocument = (document: string) => {
  const firstPart = document.slice(0, -1);
  const secondPart = document.slice(-1);
  const rut = `${firstPart}-${secondPart}`;

  return rut;
};
