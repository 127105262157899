/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Keyboard from "../../../../components/Keyboard";
import Logo from "../../../../components/Logo";
import { _pushToastMessage } from "../../../../helpers/messages";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { set_loader } from "../../../../redux/actions/loader";
import { handleAllColorStyles } from "../../../../helpers/dynamic_styles";
import { validate as validateRut } from "rut.js";
import { sleep } from "../../../../helpers/functions";
import FantasilandiaService from "../../../../services/fantasilandia.service";
import { set_order } from "../../../../redux/actions/order";
import { canCreateReservations, formatDocument } from "./helpers";
import { set_pickup } from "../../../../redux/actions/pickup";

const ValidateDocument = () => {
  const [state, setState] = useState<any>({
    data: "",
    redirect_to: "",
    keyboardLayout: {
      label: "ABC",
      value: "spanish_only_numbers",
    },
  });

  const keyboardRef = useRef<any>();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const dispatch: any = useDispatch();
  const { session } = useSelector((state: any) => state);
  const assets = session?.profile?.assets?.assets;

  useEffect(() => {
    handleAllColorStyles(assets);
    if (urlParams.has("redirect_to")) {
      setState({ ...state, redirect_to: urlParams.get("redirect_to") });
    }
  }, []);

  const _handleOnKeyPress = async (button: any) => {
    if (button === "{bksp}") {
      setState({ ...state, data: state.data.slice(0, -1) });
      return;
    }

    if (button === "{enter}") {
      const rut = formatDocument(state.data);
      const validatedRut = validateRut(rut);

      if (!validatedRut) {
        _pushToastMessage({
          type: "warning",
          text: "El rut ingresado no es válido",
          header: "Aviso",
        });
        return;
      }

      try {
        dispatch(set_loader({ is_loading: true }));
        await sleep(100)
        const response = await new FantasilandiaService().checkReservation({
          code: state.data,
          terminal_id: session?.profile?.id,
        });

        dispatch(
          set_order({
            boxes: [],
            company: {},
            order: {},
            new_order: {
              code: state.data,
              terminal_id: session?.profile?.id,
              boxes: response?.data?.boxes,
              method: "keyboard"
            },
          })
        );

        const reservation = response?.data?.reservation;

        if (!reservation) {
          const can_create_reservations = await canCreateReservations({
            terminal_id: session?.profile?.id,
          });

          dispatch(set_loader({ is_loading: false }));

          if (!can_create_reservations) return;

          navigate(`/pin-code/delivery`);
          return;
        }

        dispatch(
          set_pickup({
            reservation: reservation,
            boxes: response.data?.boxes,
          })
        );
        dispatch(set_loader({ is_loading: false }));

        const { delivery_user } = reservation;

        if (delivery_user?.delivery_user_config?.info?.method !== "document") {
          navigate("/pin-code/pickup");
          return;
        }

        navigate("/open-door/pickup");
        return;
      } catch (e) {
        dispatch(set_loader({ is_loading: false }));
        _pushToastMessage({
          type: "error",
          text: "El pin ingresado es incorrecto",
          header: "Aviso",
        });
      }

      return;
    }

    setState({ ...state, data: state.data + button });
  };

  const _handleOnChange = (value: any) => {
    console.log("ONCHANGE", value);
  };

  const _toggleKeyboard = () => {
    setState({
      ...state,
      keyboardLayout: {
        label: state.keyboardLayout?.label === "ABC" ? "123" : "ABC",
        value:
          state.keyboardLayout?.value === "spanish_only_numbers"
            ? "spanish_basic_without_space_with_ok"
            : "spanish_only_numbers",
      },
    });
  };

  return (
    <div className="container-fluid h-100">
      <div className="w-100 text-center my-4">
        <Logo
          url={assets?.events_default_logo_upload}
          style={{ maxWidth: "100px" }}
        />
      </div>
      <div className="d-flex justify-content-center align-items-center p-0">
        <div
          className=" d-flex align-items-center justify-content-around flex-column p-5"
          style={{
            background: "rgba(255, 255, 255, 0.1)",
            width: "90%",
            borderRadius: "30px",
            height: "650px",
          }}
        >
          <div className="w-100 text-center bold size-14">
            {assets?.fantasilandia_delivery_pickup_without_document_document_title ||
              "Ingresa tu rut sin puntos ni guion"}
          </div>

          <div>
            <div
              className="w-100 d-flex justify-content-center align-items-end my-4 pb-2"
              style={{
                background: "rgba(255, 255, 255, 0.1)",
                borderRadius: "10px",
                minWidth: "550px",
                minHeight: "80px",
              }}
            >
              <div
                className="bold py-0 my-0 ms-4 text-center"
                style={{
                  fontSize: "38px",
                  letterSpacing: "25px",
                }}
              >
                {state.data}
              </div>
            </div>
          </div>

          <div
            className="w-100 keyboard-number"
            style={{ color: "#000", height: "65vh" }}
          >
            <Keyboard
              onKeyPress={_handleOnKeyPress}
              onChange={_handleOnChange}
              keyboardRef={keyboardRef}
              layoutType={state.keyboardLayout?.value}
            />
          </div>
          <div className="w-100 d-flex justify-content-center p-0 m-0">
            <button
              className="main-button-yellow bold"
              style={{ width: "250px", padding: "11px" }}
              onClick={_toggleKeyboard}
            >
              {state.keyboardLayout.label}
            </button>
          </div>

          <div className="text-center my-5">
            <button
              className="px-5 py-2 main-button-yellow bold"
              onClick={() => navigate("/")}
            >
              Volver
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValidateDocument;
