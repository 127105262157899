/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Keyboard from "../../../../components/Keyboard";
import Logo from "../../../../components/Logo";
import { _pushToastMessage } from "../../../../helpers/messages";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { set_loader } from "../../../../redux/actions/loader";
import { handleAllColorStyles } from "../../../../helpers/dynamic_styles";
import { sleep } from "../../../../helpers/functions";
import { set_order } from "../../../../redux/actions/order";
import FantasilandiaService from "../../../../services/fantasilandia.service";

const PinCode = () => {
  const [state, setState] = useState<any>({
    data: "",
    redirect_to: "",
    keyboardLayout: {
      label: "ABC",
      value: "spanish_only_numbers",
    },
  });

  const keyboardRef = useRef<any>();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const params = useParams() as { action: string };
  const dispatch: any = useDispatch();
  const { session, order, pickup } = useSelector((state: any) => state);
  const assets = session?.profile?.assets?.assets;

  useEffect(() => {
    handleAllColorStyles(assets);
    if (urlParams.has("redirect_to")) {
      setState({ ...state, redirect_to: urlParams.get("redirect_to") });
    }
  }, []);

  const _handleOnKeyPress = async (button: any) => {
    if (button === "{bksp}") {
      setState({ ...state, data: state.data.slice(0, -1) });
      return;
    }

    if (button === "{enter}") {
      if (state.data?.length !== 4) {
        _pushToastMessage({
          type: "warning",
          text: "El pin debe contener 4 digitos",
          header: "Aviso",
        });
        return;
      }

      try {
        let action = params.action;
        dispatch(set_loader({ is_loading: true }));

        if (action === "delivery") {
          dispatch(
            set_order({
              boxes: [],
              company: {},
              order: {},
              new_order: {
                ...order.new_order,
                pin: state.data,
              },
            })
          );
          await sleep(100);
          dispatch(set_loader({ is_loading: false }));
          navigate("/open-door/delivery");
          return;
        }

        const { reservation } = pickup;
        const { delivery_user } = reservation;

        await new FantasilandiaService().checkPin({ pin: state.data, delivery_user_id: delivery_user.id });

        await sleep(100);
        dispatch(set_loader({ is_loading: false }));
        navigate(`/open-door/pickup`);
      } catch (e) {
        dispatch(set_loader({ is_loading: false }));
        _pushToastMessage({
          type: "warning",
          text: "El pin ingresado es incorrecto",
          header: "Aviso",
        });
        setState({ ...state, data: "" });
      }

      return;
    }

    if (state.data.length >= 4) return;

    setState({ ...state, data: state.data + button });
  };

  const _handleOnChange = (value: any) => {
    console.log("ONCHANGE", value);
  };

  return (
    <div className="container-fluid h-100">
      <div className="w-100 text-center my-4">
        <Logo
          url={assets?.events_default_logo_upload}
          style={{ maxWidth: "100px" }}
        />
      </div>
      <div className="d-flex justify-content-center align-items-center p-0">
        <div
          className=" d-flex align-items-center justify-content-around flex-column p-5"
          style={{
            background: "rgba(255, 255, 255, 0.1)",
            width: "90%",
            borderRadius: "30px",
            height: "650px",
          }}
        >
          <div className="w-100 text-center bold size-14">
            {params.action === "delivery"
              ? assets?.fantasilandia_delivery_without_document_pin_title
              : assets?.fantasilandia_pickup_without_document_pin_title}
          </div>

          <div>
            <div
              className="w-100 d-flex justify-content-center align-items-end my-4 pb-2"
              style={{
                background: "rgba(255, 255, 255, 0.1)",
                borderRadius: "10px",
                minWidth: "300px",
                minHeight: "80px",
              }}
            >
              <div
                className="bold py-0 my-0 ms-4 text-center"
                style={{
                  fontSize: "38px",
                  letterSpacing: "25px",
                }}
              >
                {state.data}
              </div>
            </div>
          </div>

          <div
            className="w-100 keyboard-number"
            style={{ color: "#000", height: "65vh" }}
          >
            <Keyboard
              onKeyPress={_handleOnKeyPress}
              onChange={_handleOnChange}
              keyboardRef={keyboardRef}
              layoutType={state.keyboardLayout?.value}
            />
          </div>

          <div className="text-center my-5">
            <button
              className="px-5 py-2 main-button-yellow bold"
              onClick={() => navigate("/")}
            >
              Volver
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PinCode;
