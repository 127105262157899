/* eslint-disable react-hooks/exhaustive-deps */
import Logo from "../../../components/Logo";
import ItemMenu from "./ItemMenu";
import { ReactComponent as Locker } from "../../../assets/svg/locker.svg";
import { ReactComponent as Box } from "../../../assets/svg/locker.svg";
import { ReactComponent as Orders } from "../../../assets/svg/package.svg";
import { ReactComponent as Companies } from "../../../assets/svg/reservation.svg";
import { ReactComponent as Modules } from "../../../assets/svg/module.svg";
import { ReactComponent as LogOut } from "../../../assets/svg/logout.svg";
import { ReactComponent as Coms } from "../../../assets/svg/coms.svg";
import { ReactComponent as Offline } from "../../../assets/svg/offline.svg";
import { IoCloudDownload } from "react-icons/io5";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { set_session } from "../../../redux/actions/session";
import { useSelector } from "react-redux";
import { INITIAL_STATE as MODULES_REDUX_STATE } from "../../../redux/reducers/modules";

const DashboardTemplate = (props: any) => {
  const { children } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const { session, modules } = useSelector((state: any) => state);

  const dynamicModules: any = [
    {
      name: "Modulo Residencial Dinamico",
      slug: "dynamic-residential-module",
    },
    {
      name: "Modulo Residencial Offline Dinamico",
      slug: "dynamic-residential-offline-module",
    },
    {
      name: "Modulo Pickup Dinamico",
      slug: "dynamic-pickup-module",
    },
    {
      name: "Modulo Pickup Box Dinamico",
      slug: "dynamic-pickup-box-module",
    },
    {
      name: "Prestamos Dinamico",
      slug: "dynamic-lending-module",
    },
    {
      name: "Eventos Dinamico",
      slug: "dynamic-events-module",
    },
    {
      name: "Fantasilandia Dinamico",
      slug: "dynamic-fantasilandia-module",
    },
  ];

  const [state, setState] = useState({
    menu: [
      {
        label: "Cajas",
        url: "/dashboard",
        Icon: Box,
        selected: false,
        show: true,
        show_to_this_modules: [
          ...MODULES_REDUX_STATE?.modules_list,
          ...dynamicModules,
        ]
          ?.filter((item: any) => (item.slug !== "residential-offline-module" && item.slug !== "dynamic-residential-offline-module"))
          .map((item: any) => item.slug),
      },
      {
        label: "Cajas",
        url: "/dashboard/boxes/offline",
        Icon: Box,
        selected: false,
        show: true,
        show_to_this_modules: ["residential-offline-module", "dynamic-residential-offline-module"],
      },
      {
        label: "Pedidos",
        url: "/dashboard/orders",
        Icon: Orders,
        selected: false,
        show: true,
        show_to_this_modules: [
          ...MODULES_REDUX_STATE?.modules_list,
          ...dynamicModules,
        ]
          ?.filter((item: any) => (item.slug !== "residential-offline-module" && item.slug !== "dynamic-residential-offline-module"))
          .map((item: any) => item.slug),
      },
      {
        label: "Pedidos",
        url: "/dashboard/orders/offline",
        Icon: Orders,
        selected: false,
        show: true,
        show_to_this_modules: ["residential-offline-module", "dynamic-residential-offline-module"],
      },
      {
        label: "Artículos",
        url: "/dashboard/lending",
        Icon: Companies,
        selected: false,
        show: true,
        show_to_this_modules: ["lending-module", "lending-unab-module", "dynamic-lending-module"],
      },
      {
        label: "Modulos",
        url: "/dashboard/modules",
        Icon: Modules,
        selected: false,
        show: true,
        show_to_this_modules: ["*"],
      },
      {
        label: "Puertos",
        url: "/dashboard/doors",
        Icon: Coms,
        selected: false,
        show: true,
        show_to_this_modules: ["*"],
      },
      {
        label: "Offline",
        url: "/dashboard/sync/offline",
        Icon: Offline,
        selected: false,
        show: true,
        show_to_this_modules: ["residential-offline-module", "dynamic-residential-offline-module"],
      },
      {
        label: "Actualización",
        url: "/dashboard/version",
        Icon: IoCloudDownload,
        selected: false,
        show: true,
        show_to_this_modules: ["*"],
      },
    ],
  });

  const dispatch: any = useDispatch();

  useEffect(() => {
    /**
     * Set selected menu
     * Priority show than dont_show
     */
    state.menu.map((menu: any) => {
      if (menu.url === location.pathname) {
        menu.selected = true;
      } else {
        menu.selected = false;
      }

      menu.show = false;

      const show = menu.show_to_this_modules.find(
        (item: any) => item === modules.module_selected || item === "*"
      );

      if (show) {
        menu.show = true;
      }

      if (
        menu.url === "/dashboard/orders" &&
        (session?.profile?.module?.slug === "lending-module" ||
          session?.profile?.module?.slug === "lending-unab-module")
      ) {
        menu.show = false;
      }

      return menu;
    });

    setState({ ...state });
  }, [location]);

  const _handleLogout = () => {
    dispatch(
      set_session({
        ...session,
        is_logged: false,
        master_key: { is_connected: false, code: "", created_at: null },
      })
    );
    navigate("/");
  };

  return (
    <div className="container-fluid h-100 background-color-base">
      <div className="row h-100 color-black">
        <div
          className=" h-100 background-color-white shadow-sm d-flex flex-column align-items-center p-0"
          style={{ width: "11%" }}
        >
          <div className="w-100 px-3 pt-3 pb-2 text-center">
            <Logo company="gux" />
          </div>

          {state.menu &&
            state.menu
              .filter((item) => item.show)
              .map((menu: any) => (
                <ItemMenu {...menu} key={`${menu.label}-${menu.url}`} />
              ))}

          <ItemMenu
            label="Cerrar"
            url="dashboard/exit"
            Icon={LogOut}
            onClick={_handleLogout}
          />
        </div>
        <div className="h-100 m-0 p-0" style={{ width: "89%" }}>
          <div
            className="w-100 background-color-gris shadow-sm px-3 size-09 d-flex justify-content-end align-items-center"
            style={{
              fontWeight: "bold",
              height: "8vh",
              borderBottom: "1px solid #f3f3f3",
            }}
          >
            {session?.profile?.username}
            <Locker
              style={{ width: "30px", height: "30px" }}
              className="ms-3"
            />
          </div>
          <div
            className="content w-100"
            style={{ height: "92vh", overflow: "auto" }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardTemplate;
